<template>
  <LayoutBasic :showLinksMenu="false" :routeBack="'/empresas'" v-if="empresa">
    <template v-slot:breadcrumbs>
      <v-breadcrumbs class="pa-3 ml-1" :items="navigation" divider=">">
        <v-breadcrumbs-item
          slot="item"
          slot-scope="{ item }"
          exact
          :to="item.to"
          >{{ item.text }}</v-breadcrumbs-item
        >
      </v-breadcrumbs>
    </template>

    <template v-slot:navigation-title>
      {{empresa.razonSocial}}
    </template>

    <template v-slot:navigation-content>
      <!-- <v-img
        :src="empleado.foto || noImage"
        :lazy-src="`https://picsum.photos/10/6?image=${15}`"
        aspect-ratio="1"
        height="150px"
        contain
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img> -->

      <v-divider light></v-divider>
      <v-list>
        <v-list-item @click="$router.push({ name: 'empresaDatosGenerales' })">
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Datos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({
              name: 'editarEmpresa',
              params: { empresa: empresa },
            })
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Editar empresa</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({
              name: 'empresaSucursales',
              params: { empresa: empresa },
            })
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Sucursales</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({
              name: 'empresaContabilidad',
              params: { empresa: empresa },

            })
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Contabilidad</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          @click="
            $router.push({
              name: 'empresaCategorias',
              params: { empresa: empresa },

            })
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Categorias</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
       
      </v-list>
    </template>

    <template v-slot:vista-previa>
      <v-container>
        <router-view :empresa="empresa"></router-view>
      </v-container>
    </template>
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import ClienteListCtaCteDetalle from "@/components/cliente/ClienteListCtaCteDetalle";
import EmpresaAPI from "@/api/empresas";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "empresa",
  components: {
    ClienteListCtaCteDetalle,
    LayoutBasic,
  },
  data() {
    return {
      search: "",
      loading: false,
      empresa: null,

      noImage: require("@/assets/man-woman.jpg"),
      navigation: [
        {
          text: "Empresas",
          disabled: false,
          to: { name: "empresas" },
        },
      ],
    };
  },

  methods: {
    async getEmpresa(uuid) {
      try {
        this.loading = true;

        const response = await EmpresaAPI.show(uuid);
        this.empresa = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: `Problema al obtener la empresa'`,
          icon: "mdi-bug",
          timeout: 2500,
        });
      }
    },
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
  },
  watch: {
    "$route.params": {
      handler: function (params) {
        if (params.empresa) {
          this.empresa = params.empresa;
        } else {
          this.getEmpresa(params.uuid_empresa);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
